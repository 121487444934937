import React, { useEffect } from "react"
import { updateVisitorCount } from "../counter"

function Footer() {
  useEffect(() => {
    updateVisitorCount()
  }, []) // Empty dependency array to run once on mount

  return (
    <footer className="page-footer purple darken-4">
      <div className="footer-copyright">
        <div className="left">
          <a href="https://github.com/Tranqui11ion" target="_blank" rel="noopener noreferrer" className="icon-padding">
            <i className="fa-brands fa-github fa-2x"></i>
          </a>
          <a href="https://www.linkedin.com/in/codyfknight/" target="_blank" rel="noopener noreferrer" className="icon-padding">
            <i className="fa-brands fa-linkedin fa-2x"></i>
          </a>
          <a href="https://x.com/Tranqui11ion" target="_blank" rel="noopener noreferrer" className="icon-padding">
            <i className="fa-brands fa-x-twitter fa-2x"></i>
          </a>
        </div>
        <div className="container">
          <p>&copy; 2024 My Website. All rights reserved.</p>
          <p>
            You are visitor number: <span id="visitor-count">Loading...</span>
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
