import React from "react"
import { Link } from "react-router-dom"

function Header() {
  return (
    <>
      <header>
        <nav>
          <div className="nav-wrapper purple darken-4">
            <Link to="/" className="brand-logo">
              My Website
            </Link>
            <ul id="nav-mobile" className="right hide-on-med-and-down">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/resume">Resume</Link>
              </li>
              <li>
                <Link to="/blog">Blog</Link>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    </>
  )
}

export default Header
