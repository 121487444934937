import React from "react"

function Home() {
  return (
    <div id="home-container">
      <h1>HOME</h1>
    </div>
  )
}

export default Home
