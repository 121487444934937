import React from "react"

function Blog() {
  return (
    <div>
      <h1>BLOG</h1>
      <p>Welcome to the Blog Page</p>
    </div>
  )
}

export default Blog
