import React from "react"

function Resume() {
  return (
    <div>
      <h1>RESUME</h1>
    </div>
  )
}

export default Resume
