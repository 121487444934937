let debounceTimeout; //The counter was incrementing by two. This is to restric that

export async function updateVisitorCount() {
  const visitorCountElement = document.getElementById("visitor-count");

  if (!visitorCountElement) {
    console.error("Visitor count element not found.");
    return;
  }

  clearTimeout(debounceTimeout);
  debounceTimeout = setTimeout(async () => {
    try {
      const response = await fetch('https://us-central1-cloud-resume-b8e0d.cloudfunctions.net/updateVisitorCount', {
        method: 'POST',
      });

      if (response.ok) {
        const result = await response.text();
        console.log(result);
        visitorCountElement.textContent = result;
      } else {
        throw new Error('Network response was not ok');
      }
    } catch (error) {
      console.error("Error updating visitor count: ", error);
      visitorCountElement.textContent = "Error";
    }
  }, 200); // Adjust the debounce delay as needed
}

document.addEventListener("DOMContentLoaded", function () {
  updateVisitorCount();
});
